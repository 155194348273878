<template>

	<!-- Layout Header ( Navbar ) -->
	<a-layout-header>
		<div class="header-col header-nav">

			<!-- Navigation Menu For Large Screens -->
			<a-menu mode="horizontal" class="d-block">
				<a-menu-item key="login" class="submenu-title-wrapper">
					<router-link to="/login" class="nav-link" @click="e => e.preventDefault()">
						<font-awesome-icon :icon="['fas', 'home']"/> 
						<span>Iniciar Sesión</span>
					</router-link>
				</a-menu-item>
				<a-menu-item key="register" class="submenu-title-wrapper">
					<router-link to="/register" class="nav-link" @click="e => e.preventDefault()">
						<font-awesome-icon :icon="['fas', 'home']"/> 
						<span>Registrarse</span>
					</router-link>
				</a-menu-item>
			</a-menu>
			<!-- / Navigation Menu For Large Screens -->

		</div>
	</a-layout-header>
	<!-- / Layout Header ( Navbar ) -->

</template>

<script>

	export default ({
		data() {
			return {
				// Collapse navigation value.
				// Binded model property for "Collapsible Navigation Menu" collapsed status .
				collapseNav: 0,
			}
		},
	})

</script>

<style>

	.nav-link svg {
		margin-right: 5px;
		vertical-align: middle;
	}
	.nav-link span {
		vertical-align: middle;
	}
	.ant-menu-submenu-popup {
		width: 100%;
	}

</style>