<!-- 
	This is the default layout, used in sign-in and sign-up pages.
 -->

<template>
	<div>

		<!-- Default Layout -->
		<a-layout class="layout-auth" id="layout-auth" :class="[layoutClass]">

			<!-- Layout Header ( Navbar ) -->
			<Header></Header>
			<!-- / Layout Header ( Navbar ) -->

			<!-- Page Content -->
			<a-layout-content>
				<router-view />
			</a-layout-content>
			<!-- / Page Content -->

			<!-- Layout Footer -->
			<Footer></Footer>
			<!-- / Layout Footer -->

		</a-layout>
		<!-- / Default Layout -->

	</div>
</template>

<script>
	import Header from '@/components/auth/layouts/Header';
	import Footer from '@/components/auth/layouts/Footer';

	export default ({
		props: ['layoutClass'],
		components: {
			Header,
			Footer,
		},
		setup(props) {
			

		}
	})

</script>